import planet from "../assets/icons/full/planet.svg";

export default [
  {
    icon: planet,
    title: "Digital Archive System",
    body:
      "Arhivski sistem za uspostavljanje Digitalne Liste Dokumentarnog materijala i arhivsko upravljanje dokumentima projektovan prema svim zahtevima Zakona o arhivskoj građi i arhivskoj delatnosti.Pored digitalizacije, mikrofilmovanja i arhiviranja po svim arhivističkim principima, služi i za dugotrajno arhiviranje dokumenata koja su izvorno nastala u elektronskoj formi podržavajući sve neophodne formate i alate za integrisanje elektronskog potpisa i elektronskog pečata.\n" +
        "Korisnicima pruža mogućnost vođenja arhivskog poslovanja na zakonom propisan način,jednostavno podešavanje,kreiranje i definisanje nivoa pristupa."
  },
  {
    icon: planet,
    title: "Apis",
    body:
      "Advanced Project Information System za praćenje svih ključnih projektnih parametara na jednom mestu. Praćenje vremena realizacije projekata, troškova i iskorišćenosti svih resursa kao i organizovanja procesa nabavke."
  },
  {
    icon: planet,
    title: "Sistem za upravljanje internom revizojom",
    body:
      "Rešenje podržava automatizaciju procesa interne revizije a time povećanje efikasnosti i transparentnosti rada revizora.  Neki od elemenata su: Objedinjavanje dokumentacije, Praćenje toka revizije, Praćenje realizacije zadataka, Olakšano izveštavanje i planiranje, Olakšano izveštavanje"
  },
  {
    icon: planet,
    title: "Informacioni sistem za automatizaciju i digitalizaciju poslovnih procesa",
    body:
      "Informacioni sistem za digitalizaciju i upravljanje poslovnim procesima obezbeđuje alate za: Analizu elemenata poslovnog procesaNotacija modeliranja poslovnih procesa (BPMN); Low code/ no code automatizaciju koraka procesa. Pomoću njih se poslovni procesi ili njihovi delovi pretaču u poslovne Internet aplikacije. Upravljanje poslovnim pravilima (Business rules engin. Krajnji korisnici mogu podešavati delove procesa kroz korisnički interfejs bez programiranja. Simulacije i testiranje poslovnih aplikacija; Integraciju poslovnih aplikacija sa drugim sistemima; Saradnju učesnika procesa; Razvoj i primenu modela mašinskog učenja. Prednosti koje informacioni sistem za digitalizaciju i upravljanje poslovnim procesima donosi su: Povećava efikasnost i smanjuje troškova. Povećava brzinu reakcije na promene u poslovnim okruženju. Omogućuje bolje upravljanje rizicima. Omogućuje povećanje nivo zadovoljstva korisnika usluga i zaposlenih"
  },
  {
    icon: planet,
    title: "Sistem za dokumentovanje poslovnih procesa",
    body:
      "U današnjem brzom poslovnom okruženju, efikasnost i jasnoća poslovnih procesa su najvažniji. Mnoge organizacije pate od zastarelih, nepristupačnih ili loše dokumentovanih procesa, što dovodi do neefikasnosti, grešaka i nedostatka standardizacije. Da bismo ovo rešili, potrebna je implementacija sveobuhvatnog sistema za dokumentovanje poslovnih procesa. Ova platforma služi kao centralno skladište za sve organizacione procese, pružajući zaposlenima lak pristup ažurnoj dokumentaciji, smernicama i najboljim praksama."
  },
  {
    icon: planet,
    title: "Sistem za upravljanje javnim nabavkama",
    body:
      "Sistem omogućuje efikasnu pripremu i sprovođenje procesa javnih nabavki, kao i praćenje realizacije ugovora. Neke od glavnih celina su: Iskazivanje i objedinjavanje potreba; Planiranje; Sprovođenje postupka; Praćenje realizacije nabavki; Trebovanja; Izveštavanje"
  },
  {
    icon: planet,
    title: "Sistem za upravljanje ljudskim resursima",
    body:
      "Sistem u potpunosti podržava napredno upravljanje ljudskim resursima u velikim poslovnim sistemima. Neke od glavnih celina su: Organizacija i sistematizacija; Kadrovi; Izrada radno pravne dokumentacije; Portal za zaposlene; Portal za rukovodioce; Evidencija prisustva na poslu; Obrada velikog broja zahteva; Regrutacija i selekcija; Razvoj zaposlenih;"
  },
  {
    icon: planet,
    title: "Sistem za upravljanje prigovorima žalbama",
    body:
      "Sistem omogućuje efikasno upravljanje prigovorima i žalbama koje se mogu prikupiti kroz različite kanale (sajt, kol centar, mejl, neposredno). Neke od glavnih celina su: Prikupljanje prigovora i žalbi kroz različite kanale; Objedinjavanje predmeta; Praćenje toka rešavanja; Rokovi i alarmi; Komunikacija sa podnosiocima; Izveštavanje i statistika;"
  },
  {
    icon: planet,
    title: "UTILITY: Smart Grid based",
    body:
      "Pojam energetske efikasnosti sve češće se sreće u svakodnevnom životu, a efikasno korišćenje energije pre svega podrazumeva izbegavanje njenog nepotrebnog rasipanja. Istovremeno, gubici koji nastaju tokom transformacije iz jednog oblika energije u drugi, kao i tokom distribucije energije do krajnjih korisnika, treba da budu svedeni na minimum. Modernizacija industrijske tehnologije predstavlja važan aspekt u ukupnoj uštedi energije i smanjenju zagađenja."
  },
  {
    icon: planet,
    title: "eHEALTH: Patient-centric",
    body:
      "Platforma za zdravstvenu zaštitu predstavlja integrisani sistem koji obuhvata sve module koje jedan zdravstveni sistem treba da sadrži. Ovo je preventivni i dijagnostički sistem koji pokriva sve aspekte rešenja (uključujući infrastrukturu, hardver, softver i usluge povezivanja) i omogućava usko integrisano rešenje i komunikacionu platformu među svim učesnicima u projektu. Ovo je jedan od najvažnijih sistema u svakoj modernoj lokalnoj zajednici. Naša platforma za zdravstvenu zaštitu pruža mnoge mogućnosti za kontrolu i održavanje registra pacijenata, portala za pacijente, sistema za radiologiju, laboratorijske analize, e-recepte, bolovanja, uputnice, zdravstveno osiguranje, sistem naplate i još mnogo toga."
  },
  {
    icon: planet,
    title: "SMART City: IoT Driven",
    body:
      "Pametni grad koristi okvir informacionih i komunikacionih tehnologija za kreiranje, implementaciju i promociju razvojnih praksi kako bi odgovorio na urbane izazove i stvorio tehnološki povezanu i održivu infrastrukturu. Tehnologija koristi različite softvere, korisničke interfejse i komunikacione mreže zajedno sa Internetom stvari (IoT) kako bi omogućila povezane usluge za građane. Od svih ovih tehnologija, IoT je najvažniji. IoT je mreža povezanih uređaja koji međusobno komuniciraju i razmenjuju podatke. Podaci prikupljeni sa ovih uređaja čuvaju se u oblaku ili na serverima, što omogućava unapređenje efikasnosti u javnom i privatnom sektoru, kao i ekonomske koristi i poboljšanje kvaliteta života građana."
  },
  {
    icon: planet,
    title: "TELCO: Telcom Operators",
    body:
      "Based on decades of experience, supporting telco operators throughout the world (from Tier 1 to alternative operators) and participating in their challenges from early digitalization to digital transformation we have gathered in-depth experience of the transformations and challenges telecoms are facing."
  }










  // {
  //   icon: planet,
  //   title: "Tour Guide",
  //   body:
  //     "Doživite svako turističko i poslovno putovanje na jedinstven i kreativan način. Pronadji lokalnog vodiča i uživaj na bilo kom mestu na planeti."
  // },
  // {
  //   icon: planet,
  //   title: "Zup! App",
  //   body:
  //     "Napravi fotografiju – postavi izazov. Poveži se sa ljudima slicčnih interesovanja sširom sveta i razmenjuj kreativne i uzbudljive trenutke iz svakodnevnog života."
  // },
  // {
  //   icon: planet,
  //   title: "Persona+",
  //   body:
  //     "Zamisli… Novi, paralelni VR svet oko sebe, svet bez ograničenja sa pregršt mogucćnosti i izazova…"
  // },
  // {
  //   icon: planet,
  //   title: "Smart Nodes",
  //   body:
  //     "Pametne, unapredjene police. Praćenje detaljnih podataka prodaje pojedinacčnih artikala i kreiranje heat mapa nikada nije bilo brze i preciznije. Praćenje podataka u realnom vremenu širom planete – na jednom mestu."
  // },
];
