import save from "../assets/icons/outlined/save.svg";
import computer from "../assets/icons/outlined/computer.svg";
import briefcase from "../assets/icons/outlined/briefcase.svg";

export default [
  {
    icon: save,
    title: "Digitalizacija & DMS",
    body:
      "Naša rešenja i pristup radu omogućavaju nam da svojim klijentima pružimo izuzetno kvalitetnu podršku u dostizanju maksimalne optimizacije i pouzdanosti poslovanja. Naša ekspertiza se ogleda u digitalizaciji, skeniranju i mikrofilmovanju poslovne dokumentacije po najvišim standardima arhivističke delatnosti i u skladu sa svim važećim zakonima."
  },
  {
    icon: computer,
    title: "Planiranje i razvoj IT & IOT rešenja",
    body:
      "U skladu sa savremnenim trendovima i modernizacijom poslovanja, u mogućnosti smo da ponudimo uslugu osmišljavanja i razvoja informacionih rešenja koja su u potpunosti u skladu sa potrebama klijenta, poslovnim normama ali i zakonskim i pravnim propisima. Posebno smo ponosni našim gotovim rešenjima za upravljanje poslovnim procesima koja su u upotrebi i kod velikih, internacionalnih klijenata."
  },
  {
    icon: computer,
    title: "Veštačka inteligencija (AI)",
    body:
      "Naša kompanija se bavi razvojem inovativnih rešenja zasnovanih na veštačkoj inteligenciji, koja omogućavaju automatizaciju procesa, analizu podataka i donošenje pametnih odluka u različitim industrijama, doprinosivši efikasnosti i modernizaciji poslovanja."
  },
  {
    icon: computer,
    title: "Cajber bezbednost (Cyber Security)",
    body:
      "Pruža napredna rešenja u oblasti sajber bezbednosti, fokusirana na zaštitu podataka, prevenciju pretnji i osiguravanje sigurnosti digitalnih sistema, čime omogućavamo pouzdano i bezbedno poslovno okruženje za naše klijente."
  },
  {
    icon: briefcase,
    title: "Menadžment konsalting",
    body:
      "Kao posebnu delatnost naše kompanije ističemo konsultantske  usluge na polju menadžmenta i to upravljanje kompanijama, timovima i projektima. Naše usluge su od značaja kako za male i srednje kompanije, tako i za velike internacionalne igrače na poljima energetike, telekomunikacija i informacionih tehnologija."
  }
];
