import cocaCola from "../assets/portfolio/coca-cola.png";
import vladaCG from "../assets/portfolio/vladaCG.png";
import zelezniceSrbije from "../assets/portfolio/zeleznica.png";
import adriamediagroup from "../assets/portfolio/adg.png";
import kss from "../assets/portfolio/kss2.png";
import uniqa from "../assets/portfolio/uniqa.png";
import beograd from "../assets/portfolio/beograd.png";
import nationalAssembly from "../assets/portfolio/NationalAssembly.png";
import vladaRS from "../assets/portfolio/vladaRS.png";
import bgInvest from "../assets/portfolio/bginvest2.png";
import nites from "../assets/portfolio/nites.png";
import mtel from "../assets/portfolio/mtel.png";
import devSerbia from "../assets/portfolio/devserbia2.png";
import projektura from "../assets/portfolio/projektura.png";
import navak from "../assets/portfolio/navak.png";
import faschang from "../assets/portfolio/faschang.png";
import petrolOfisi from "../assets/portfolio/petrolOfisi.png";
import omv from "../assets/portfolio/omv.png";
import ostrog from "../assets/portfolio/ostrog.png";
import atos from "../assets/portfolio/Atos-logo-1024x724.jpg";
import ingram from "../assets/portfolio/Ingram Micro.png";
import isailovic from "../assets/portfolio/Isailovic and partners.png";
// import medit from "../assets/portfolio/medit.png";
import telekom from "../assets/portfolio/telekom-srbija-logo-1500x1200px.jpg";
import uon from "../assets/portfolio/UON-logo.png";
import airodrom from "../assets/portfolio/airodrom.png";
import nis from "../assets/portfolio/nis-grad.png";
import pijace from "../assets/portfolio/pijace.png";
import vodovod from "../assets/portfolio/vodovod.png";
import entel from "../assets/portfolio/Enetel.jfif";
// import min_z from "../assets/portfolio/Logo_Ministarstva_Zdravlja.jpg";
import min_f from "../assets/portfolio/ministarstvo-finansija-logo.jpg";
import min_zs from "../assets/portfolio/MINISTARSTVO-ZASTITE-ZIVOTNE-SREDINE.jpg";
import mts from "../assets/portfolio/MTS sistemi.png";
import posta_slo from "../assets/portfolio/Posta-SLO.jpg";
import roaming from "../assets/portfolio/roaming-electronics-bela.jpg";
import supernova from "../assets/portfolio/Supernova-logo-600x600-1.jpg";
import toplane from "../assets/portfolio/Toplane.png";
import dunavosiguranje from "../assets/portfolio/Dunav-osiguranje-Logo.jpg";
import paoalto from "../assets/portfolio/paoalto.png";
import ibm from "../assets/portfolio/ibm2.png";

export default [
  // {
  //   image: vladaRS,
  //   title: "Vlada Republike Srbije",
  //   servicesProvided: ["Scanning and Microfilming", "DMS implementation"],
  //   categories: ["Software Development"]
  // },
  // {
  //   image: nationalAssembly,
  //   title: "National Assembly - Parlament",
  //   servicesProvided: ["Scanning and Microfilming", "DMS implementation"],
  //   categories: ["Business Analysis", "Consulting", "Software Development"]
  // },
  
  
  {
    image: paoalto,
    title: "Palo Alto Networks",
    servicesProvided: [],
    categories: ["Partners"]
  },
  {
    image: ibm,
    title: "IBM",
    servicesProvided: [],
    categories: ["Partners"]
  },
  {
    image: telekom,
    title: "Telekom Srbija",
    servicesProvided: [],
    categories: ["Consulting"]
  },
  {
    image: zelezniceSrbije,
    title: "Zeleznice Srbije",
    servicesProvided: ["Business consulting"],
    categories: ["Business Analysis"]
  },
  {
    image: atos,
    title: "Atos SE",
    servicesProvided: [],
    categories: ["Consulting"]
  },
  {
    image: ingram,
    title: "Ingram Micro",
    servicesProvided: [],
    categories: ["Consulting"]
  },
  {
    image: cocaCola,
    title: "Coca-Cola HBC Srbija",
    servicesProvided: [
      "Problem identifications and analysis",
      "Software development",
      "IoT POC engineering"
    ],
    categories: ["Business Analysis", "Engineering", "Software Development"]
  },
  {
    image: omv,
    title: "OMV SRBIJA",
    servicesProvided: [
      "Business consulting services",
      "Market research and analysis"
    ],
    categories: ["Business Analysis", "Consulting"]
  },
  {
    image: omv,
    title: "OMV R&M GMBH Austria",
    servicesProvided: [
      "Business consulting services on the International level",
      "Software solutions support and analysis"
    ],
    categories: ["Business Analysis", "Consulting"]
  },
  {
    image: mtel,
    title: "M:TEL",
    servicesProvided: [
      "Software development",
      "DMS implementation",
      "Scanning and microfilming"
    ],
    categories: ["Software Development"]
  },
  // {
  //   image: vladaCG,
  //   title: "Vlada republike Crne Gore",
  //   servicesProvided: ["Scanning and Microfilming", "DMS implementation"],
  //   categories: ["Business Analysis"]
  // },
  {
    image: nis,
    title: "Grad Niš",
    servicesProvided: [],
    categories: []
  },
  {
    image: airodrom,
    title: "AERODROM KONSTANTIN VELIKI NIŠ",
    servicesProvided: [],
    categories: []
  },
  {
    image: pijace,
    title: "JKP Gradske Pijace, Beograd",
    servicesProvided: [],
    categories: []
  },
  // {
  //   image: nites,
  //   title: "NITES",
  //
  //   servicesProvided: ["Business consulting"],
  //   categories: ["Consulting"]
  // },
  {
    image: bgInvest,
    title: "BG Invest",
    servicesProvided: ["Business consulting", "Software development"],
    categories: ["Business Analysis", "Consulting", "Software Development"]
  },
  {
    image: isailovic,
    title: "Advokatska kancelarija Isailović & Partners",
    servicesProvided: [],
    categories: ["Consulting"]
  },
  {
    image: vodovod,
    title: "JKP Naissus, Niš",
    servicesProvided: [],
    categories: []
  },
  {
    image: ostrog,
    title: "Manastir Ostrog",
    servicesProvided: [],
    categories: ["Consulting"]
  },
  {
    image: uniqa,
    title: "Uniqa Osiguranje",
    servicesProvided: [" Business consulting", " IT Market research"],
    categories: ["Business Analysis", "Consulting"]
  },
  // {
  //   image: adriamediagroup,
  //   title: "Adria Media Group Srbije",
  //   servicesProvided: [""],
  //   categories: ["Business Analysis"]
  // },
  {
    image: kss,
    title: "Košarkaški Savez Srbije",
    servicesProvided: ["Scanning and Microfilming", "DMS implementation"],
    categories: ["Business Analysis", "Consulting", "Software Development"]
  },
  {
    image: devSerbia,
    title: "DEVSERBIA LTD",
    servicesProvided: ["Business development consulting"],
    categories: ["Business Analysis", "Consulting"]
  },
  {
    image: projektura,
    title: "Projektura",
    servicesProvided: ["IT Consulting"],
    categories: ["Consulting"]
  },
  {
    image: navak,
    title: "NAVAK",
    servicesProvided: ["Business consulting", "Engineering"],
    categories: ["Business Analysis", "Engineering"]
  },
  {
    image: faschang,
    title: "Faschang & Services GMBH",
    servicesProvided: [
      "Data analysis and research",
      "Business support",
      "Software solution customization"
    ],
    categories: [
      "Business Analysis",
      "Engineering",
      "Consulting",
      "Software Development"
    ]
  },
  {
    image: petrolOfisi,
    title: "Petrol Ofisi Turkey",
    servicesProvided: [
      "Project management",
      "Software development and implementation"
    ],
    categories: ["Business Analysis", "Consulting"]
  },
  // {
  //   image: medit,
  //   title: "MEDIT DOO",
  //   servicesProvided: [],
  //   categories: ["Consulting"]
  // },
  // {
  //   image: uon,
  //   title: "UON Solutions",
  //   servicesProvided: [],
  //   categories: ["Software Development"]
  // },
  // {
  //   image: beograd,
  //   title: "Grad Beograd",
  //   servicesProvided: ["Scanning and Microfilming", "DMS implementation"],
  //   categories: ["Business Analysis", "Consulting", "Software Development"]
  // },
  // {
  //   image: entel,
  //   title: "Entel solutions",
  //   servicesProvided: [],
  //   categories: ["Software Development"]
  // },
  // {
  //   image: min_z,
  //   title: "Ministarstvo zdravlja",
  //   servicesProvided: [],
  //   categories: ["Software Development"]
  // },
  {
    image: min_f,
    title: "Ministarstvo finansija",
    servicesProvided: [],
    categories: ["Software Development"]
  },
  {
    image: min_zs,
    title: "Ministarstvo zaštite životne sredine",
    servicesProvided: [],
    categories: ["Software Development"]
  },
  {
    image: mts,
    title: "MTS",
    servicesProvided: [],
    categories: ["Software Development"]
  },
  {
    image: posta_slo,
    title: "Pošta Slovenije",
    servicesProvided: [],
    categories: ["Software Development"]
  },
  // {
  //   image: roaming,
  //   title: "Roaming electronics",
  //   servicesProvided: [],
  //   categories: ["Software Development"]
  // },
  {
    image: supernova,
    title: "Supernova",
    servicesProvided: [],
    categories: ["Software Development"]
  },
  {
    image: toplane,
    title: "Toplane Niš",
    servicesProvided: [],
    categories: ["Software Development"]
  },
  {
    image: dunavosiguranje,
    title: "Dunav osiguranje",
    servicesProvided: [],
    categories: []
  },

];
